<template>
    <el-dialog :append-to-body="true" :visible.sync="visible_in_line" :close-on-click-modal="true" :show-close="false"
        width="440px">
        <div>
            <img
                src="https://img.freepik.com/free-vector/happy-friends-drinking-wine-beer-together-flat-illustration_74855-16152.jpg?size=626&ext=jpg&ga=GA1.1.1224184972.1711756800&semt=ais"></img>

            <p class="break-word text-2xl font-semibold text-center">Chúc mừng</p>
            <p class="break-word mt-1 text-xl text-center">Quý khách đã nhận được tiền hoa hồng từ mã tiếp thị . Số tiền
                :
                <b v-if="aff_history" class="text-success">{{ p__renderMoneyByCurrency(aff_history.amount,
                    aff_history.unit)}}</b>
            </p>

            <div class="flex">
                <el-button type="primary" size="small" class="mx-auto mt-4" @click="visible_in_line = false">
                    Tôi đã xem
                </el-button>
            </div>
        </div>
    </el-dialog>
</template>

<script>
export default {
    props: ['visible', 'aff_history'],

    computed: {
        visible_in_line: {
            get() {
                return this.visible
            },
            set(value) {
                this.$emit('update:visible', value)
            }
        }
    }
}
</script>
