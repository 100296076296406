export default {
    methods: {
        m__ValidateForm(refs) {
            return new Promise((resolve, reject) => {
                if (!this.$refs[refs]) {
                    reject('refs not found')
                    return
                }

                this.$refs[refs].validate((valid) => {
                    if (valid) {
                        resolve(true)
                    } else {
                        reject('not valid')
                    }
                })
            })
        }
    }
}
