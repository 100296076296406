<template>
    <div>
        <div class="component-box flex w-[620px] mx-auto">
            <div class="component-box flex-1 mx-auto">
                <p class="text-md text-center">
                    {{ $t('common.balance') }}
                </p>

                <p class="text-success mt-4 text-4xl font-semibold text-center">
                    {{ p__formatCurrency(vnd_cashback, 'VND') }}
                </p>

                <div class="flex">
                    <el-button
                        class="mx-auto mt-6"
                        size="small"
                        @click="show_tutorial_drawer = true"
                    >
                        Hướng dẫn tiếp thị
                    </el-button>
                </div>
            </div>

            <div class="h-[100px] w-[1px] bg-bg self-center"></div>
            <div class="component-box flex-1 mx-auto">
                <p class="text-md text-center">
                    {{ $t('page.affiliate.code') }}
                </p>

                <p class="mt-4 text-3xl font-semibold text-center">
                    {{ data_code }}
                </p>

                <div class="flex">
                    <el-button
                        icon="el-icon-copy-document"
                        class="mx-auto mt-2"
                        @click="p__CopyText(data_code)"
                    >
                        {{ $t('common.copy') }}
                    </el-button>
                </div>
            </div>
        </div>

        <div class="component-box w-full mt-4">
            <div class="flex items-center mb-2">
                <p class="font-semibold">Yêu cầu rút tiền</p>

                <el-button
                    class="ml-auto"
                    type="primary"
                    size="small"
                    @click="show_create_request_dialog = true"
                >
                    Rút tiền
                </el-button>
            </div>
            <el-table :data="request_list">
                <el-table-column label="#" type="index" width="60" />

                <el-table-column label="ID yêu cầu" width="180">
                    <template slot-scope="scope">
                        {{ scope.row._id }}
                    </template>
                </el-table-column>

                <el-table-column label="Số tiền rút" width="180">
                    <template slot-scope="scope">
                        {{ p__formatNumberHasCommas(scope.row.amount) }} VND
                    </template>
                </el-table-column>

                <el-table-column label="Phí rút" width="180">
                    <template slot-scope="scope">
                        {{ p__formatNumberHasCommas(scope.row.fee) }} VND
                    </template>
                </el-table-column>

                <el-table-column label="Ngân hàng" width="240">
                    <template slot-scope="scope">
                        <p>Ngân hàng : {{ scope.row.bank_name }}</p>
                        <p>Số tài khoản : {{ scope.row.bank_account_id }}</p>
                        <p>Tên tài khoản : {{ scope.row.bank_account_name }}</p>
                    </template>
                </el-table-column>

                <el-table-column label="Trạng thái" width="240">
                    <template slot-scope="scope">
                        <el-tag v-if="scope.row.status === 0" type="warning"
                            >Đang chờ</el-tag
                        >
                        <el-tag
                            v-else-if="scope.row.status === -2"
                            type="danger"
                            >Bị từ chối</el-tag
                        >
                        <el-tag v-else type="success">Đã rút tiền</el-tag>
                    </template>
                </el-table-column>

                <el-table-column label="Lý do bị từ chối" width="240">
                    <template slot-scope="scope">
                        <p>{{ scope.row.rejected_reason }}</p>
                    </template>
                </el-table-column>

                <el-table-column label="Thao tác">
                    <template slot-scope="scope">
                        <el-button
                            v-if="scope.row.status === 0"
                            size="small"
                            type="danger"
                            @click="showCancelRequestDialog(scope.row._id)"
                        >
                            Hủy yêu cầu
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>

        <el-dialog
            title="Tạo yêu cầu rút tiền"
            :visible.sync="show_create_request_dialog"
            :close-on-click-modal="true"
            :append-to-body="true"
            width="400px"
        >
            <el-form
                ref="form_create_withdraw"
                :model="form_create_withdraw"
                :rules="rule_form_create_withdraw"
                label-position="left"
                class="space-y-7 flex flex-col"
            >
                <el-form-item prop="amount">
                    <label-outside-input-select label="Số tiền" class="w-full">
                        <el-input
                            ref="email"
                            v-model="form_create_withdraw.amount"
                            :placeholder="$t('page.login.placeholder.username')"
                            name="amount"
                            type="text"
                            tabindex="1"
                            auto-complete="on"
                            clearable
                        />
                    </label-outside-input-select>
                </el-form-item>

                <el-form-item prop="bank_name">
                    <label-outside-input-select
                        label="Ngân hàng"
                        class="w-full"
                    >
                        <el-select
                            v-model="form_create_withdraw.bank_name"
                            filterable
                            class="w-full"
                            :placeholder="$t('input.placeholder.please_select')"
                        >
                            <el-option
                                v-for="item in vn_bank_list"
                                :key="item.vn_name"
                                :label="item.vn_name"
                                :value="item.vn_name"
                            >
                            </el-option>
                        </el-select>
                    </label-outside-input-select>
                </el-form-item>

                <el-form-item prop="bank_account_id">
                    <label-outside-input-select
                        label="Số tài khoản ngân hàng"
                        class="w-full"
                    >
                        <el-input
                            ref="email"
                            v-model="form_create_withdraw.bank_account_id"
                            :placeholder="$t('page.login.placeholder.username')"
                            name="bank_account_id"
                            type="text"
                            tabindex="1"
                            auto-complete="on"
                            clearable
                        />
                    </label-outside-input-select>
                </el-form-item>

                <el-form-item prop="bank_account_name">
                    <label-outside-input-select
                        label="Tên chủ tài khoản"
                        class="w-full"
                    >
                        <el-input
                            ref="email"
                            v-model="form_create_withdraw.bank_account_name"
                            :placeholder="$t('page.login.placeholder.username')"
                            name="bank_account_name"
                            type="text"
                            tabindex="1"
                            auto-complete="on"
                            clearable
                        />
                    </label-outside-input-select>
                </el-form-item>

                <el-form-item prop="contact">
                    <label-outside-input-select
                        label="Thông tin liên hệ"
                        class="w-full"
                    >
                        <el-input
                            ref="email"
                            v-model="form_create_withdraw.contact"
                            :placeholder="$t('page.login.placeholder.username')"
                            name="contact"
                            type="text"
                            tabindex="1"
                            auto-complete="on"
                            clearable
                        />
                    </label-outside-input-select>
                </el-form-item>

                <div class="flex">
                    <el-button
                        class="ml-auto"
                        icon="el-icon-plus"
                        type="primary"
                        size="medium"
                        @click="submitForm()"
                        >{{ $t('button.create') }}
                    </el-button>
                </div>
            </el-form>
        </el-dialog>

        <el-dialog
            title="Hủy yêu cầu rút tiền"
            :visible.sync="show_cancel_request_dialog"
            :close-on-click-modal="true"
            :append-to-body="true"
            width="400px"
        >
            <p>Lí do hủy yêu cầu</p>
            <el-input v-model="reason_cancel" type="textarea"> </el-input>

            <div class="flex mt-4">
                <el-button
                    class="ml-auto"
                    type="primary"
                    size="small"
                    @click="cancelWithdrawResquest()"
                    >Hủy yêu cầu
                </el-button>
            </div>
        </el-dialog>

        <el-drawer
            direction="rtl"
            title="Hướng dẫn tiếp thị"
            :visible.sync="show_tutorial_drawer"
            :close-on-click-modal="true"
            :append-to-body="true"
            width="480px"
        >
            <div class="p-8">
                <p>
                    {{ $t('page.affiliate.desc') }}
                </p>
                <p class="mt-6 font-semibold">
                    {{ $t('page.affiliate.desc_1') }}
                </p>

                <div class="component-box flex-1 mx-auto">
                    <p class="text-md text-center">
                        {{ $t('page.affiliate.code') }}
                    </p>

                    <p class="mt-4 text-4xl font-semibold text-center">
                        {{ data_code }}
                    </p>

                    <div class="flex">
                        <el-button
                            icon="el-icon-copy-document"
                            class="mx-auto mt-2"
                            @click="p__CopyText(data_code)"
                        >
                            {{ $t('common.copy') }}
                        </el-button>
                    </div>
                </div>

                <p class="mt-6 font-semibold">
                    {{ $t('page.affiliate.desc_2') }}
                </p>

                <img
                    src="https://img.freepik.com/free-vector/sharing-gifts-online-abstract-concept-vector-illustration-online-celebration-unpacking-present-video-sending-greetings-camera-opening-gift-virtual-party-sharing-fun-abstract-metaphor_335657-4232.jpg?size=338&ext=jpg&ga=GA1.1.553209589.1714089600&semt=ais"
                    class="h-[200px] mx-auto"
                    alt=""
                />

                <p class="mt-6 font-semibold">
                    {{ $t('page.affiliate.desc_3') }}
                </p>
                <img
                    :src="require('@/assets/images/affiliate.png')"
                    class="w-full"
                    alt=""
                />
                <p v-html="$t('page.affiliate.desc_3_1')"></p>

                <p class="mt-6 font-semibold">
                    {{ $t('page.affiliate.desc_4') }}
                </p>
                <img
                    src="https://cdni.iconscout.com/illustration/premium/thumb/affiliate-marketing-5070025-4231667.png"
                    class="h-[200px] mx-auto"
                    alt=""
                />
            </div>
        </el-drawer>

        <CongratsDialog
            :visible.sync="show_congrats_dialog"
            :aff_history="receive_aff_history"
        ></CongratsDialog>
    </div>
</template>

<script>
import {
    getCurrencyInits,
    getCodeAffiliate,
    getWithdrawResquests,
    cancelWithdrawResquest,
    createWithdrawResquest
} from '@/services/atosa/affiliate'
import ValidateFormMixin from '@/plugins/mixins/validate-form'
import { getInfo } from '@/services/atosa/auth'
import { getAffiliateHistory } from '@/services/atosa-tiktok-ads/affiliate-history'
import VnBankList from './vn-bank.json'
import CongratsDialog from './congrats-dialog'

export default {
    components: {
        CongratsDialog
    },

    mixins: [ValidateFormMixin],

    data() {
        return {
            receive_aff_history: null,
            show_congrats_dialog: false,
            vn_bank_list: [],
            show_create_request_dialog: false,
            show_tutorial_drawer: false,
            data_code: '',
            vnd_cashback: 0,
            withdraw_fee_max: 0,
            withdraw_min: 0,
            withdraw_fee_percent: 0,
            request_list: [],
            show_cancel_request_dialog: false,
            cancel_request_id: '',
            reason_cancel: '',
            form_create_withdraw: {
                amount: 0,
                bank_name: '',
                bank_account_id: '',
                bank_account_name: '',
                contact: '',
                currency: 'VND_CASHBACK'
            },
            rule_form_create_withdraw: {
                amount: [
                    {
                        required: true,
                        message: this.$t('input.rule.not_empty'),
                        trigger: 'blur'
                    }
                ],
                bank_name: [
                    {
                        required: true,
                        message: this.$t('input.rule.not_empty'),
                        trigger: 'blur'
                    }
                ],
                bank_account_id: [
                    {
                        required: true,
                        message: this.$t('input.rule.not_empty'),
                        trigger: 'blur'
                    }
                ],
                bank_account_name: [
                    {
                        required: true,
                        message: this.$t('input.rule.not_empty'),
                        trigger: 'blur'
                    }
                ]
            }
        }
    },

    computed: {
        s__end_domain() {
            return this.$store.getters.app.s__end_domain
        }
    },

    mounted() {
        this.vn_bank_list = VnBankList

        this.getCodeAffiliate()

        this.getUserInfo()

        this.getWithdrawResquests()

        this.getCurrencyInits()

        this.getAffiliateHistory()
    },

    methods: {
        async getAffiliateHistory() {
            const response = await getAffiliateHistory()

            if (response.data) {
                this.receive_aff_history = response.data

                this.show_congrats_dialog = true
            }
        },

        showCancelRequestDialog(id) {
            this.show_cancel_request_dialog = true
            this.cancel_request_id = id
            this.reason_cancel = ''
        },

        async cancelWithdrawResquest() {
            const response = await cancelWithdrawResquest(
                this.cancel_request_id,
                this.reason_cancel
            )
        },

        submitForm() {
            this.$refs['form_create_withdraw'].validate((valid) => {
                if (valid) {
                    this.createWithdrawResquest()
                }
            })
        },

        async createWithdrawResquest() {
            try {
                const response = await createWithdrawResquest({
                    ...this.form_create_withdraw,
                    amount: +this.form_create_withdraw.amount
                })
            } catch (error) {
                console.error(error)
            }
        },

        async getWithdrawResquests() {
            const response = await getWithdrawResquests()

            this.request_list = response.data
        },

        async getCurrencyInits() {
            const response = await getCurrencyInits()

            if (response.data) {
                const finded = response.data.code === 'VND_CASHBACK'

                if (finded) {
                    this.withdraw_min = response.data.withdraw_min
                    this.withdraw_fee_max = response.data.withdraw_fee_max
                    this.withdraw_fee_percent =
                        response.data.withdraw_fee_percent
                }
            }
        },

        async getUserInfo() {
            const response = await getInfo()

            if (response.data && response.data.store.balance?.VND_CASHBACK) {
                this.vnd_cashback = response.data.store.balance.VND_CASHBACK
            }
        },

        async getCodeAffiliate() {
            const response = await getCodeAffiliate()

            if (this.s__end_domain === 'asia') {
                this.data_code = `TIKTOK_ADS:${response.data.TIKTOK_ADS}`
            } else {
                this.data_code = `TIKTOK_ADS_GLOBAL:${response.data.TIKTOK_ADS_GLOBAL}`
            }
        }
    }
}
</script>
