import request from './_config'

export function getCodeAffiliate() {
    return request({
        url: 'v1/store/cashback/ref?channels=tiktok_ads',
        method: 'get'
    })
}

export function getCurrencyInits() {
    return request({
        url: 'v1/store/sys/currency-units',
        method: 'get'
    })
}

export function getWithdrawResquests() {
    return request({
        url: 'v1/store/withdraw',
        method: 'get'
    })
}

export function cancelWithdrawResquest(id, reason) {
    return request({
        url: `v1/store/withdraw/${id}?reason=${reason}`,
        method: 'delete'
    })
}

export function createWithdrawResquest(data) {
    return request({
        url: `v1/store/withdraw`,
        method: 'post',
        data
    })
}
